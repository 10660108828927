<style scoped>
.main{padding:10px; min-width:1400px; min-height: 740px}
.pad{align-items: stretch; margin-bottom: 20px;}
.center{text-align: center;}
.text{color:#20323a; font-weight: bold; text-shadow: 1px 1px 3px #999;}
.huge{font-size:68px; }
.big{font-size:54px; }
.grate{font-size:40px;}
.date{font-weight: normal;font-size:40px; color: #444}
.middle{font-size:30px; }
.fix-height{height:82px; line-height: 82px;}
.small{font-size:16px; font-weight: normal;}
.chart-box{width:100%; height:270px;}
.data-box{display: flex; justify-content: space-between; align-items: baseline;}
</style>
<style>
.main .el-card{width:100%; height:100%; display: flex; flex-direction: column; align-items: stretch;}
.main .el-card__header{padding:10px 20px; font-size:20px; background: #20323a; color:#fff; font-weight: 100}
.main .el-card__body{flex:1 1 auto; background-color: #f6f6fa}
</style>
<template>
    <div class="component main">
        <!--第零行-->
        <el-row :gutter="20" class="pad">
            <el-col :span="6">
                <el-card>
                    <template #header>
                        <div class="card-header">
                            <span>产权汇总</span>
                        </div>
                    </template>
                    <el-row>
                        <el-col :span="12" class="center text">
                            <div class="grate fix-height" v-html="house_count+shop_count+parking_count"></div>
                            <div class="small">数量（套）</div>
                        </el-col>
                        <el-col :span="12" class="center text">
                            <div class="grate fix-height" v-html="(house_area+shop_area+parking_area).toFixed(2)"></div>
                            <div class="small">面积（平米）</div>
                        </el-col>                        
                    </el-row>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card>
                    <template #header>
                        <div class="card-header">
                            <span>住宅</span>
                        </div>
                    </template>
                    <el-row>
                        <el-col :span="12" class="center text">
                            <div class="grate fix-height" v-html="house_count"></div>
                            <div class="small">产权数量（套）</div>
                        </el-col>
                        <el-col :span="12" class="center text">
                            <div class="grate fix-height" v-html="house_area"></div>
                            <div class="small">产权面积（平米）</div>
                        </el-col>                        
                    </el-row>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card>
                    <template #header>
                        <div class="card-header">
                            <span>商业</span>
                        </div>
                    </template>
                    <el-row>
                        <el-col :span="12" class="center text">
                            <div class="grate fix-height" v-html="shop_count"></div>
                            <div class="small">产权数量（套）</div>
                        </el-col>
                        <el-col :span="12" class="center text">
                            <div class="grate fix-height" v-html="shop_area"></div>
                            <div class="small">产权面积（平米）</div>
                        </el-col>                        
                    </el-row>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card>
                    <template #header>
                        <div class="card-header">
                            <span>车位</span>
                        </div>
                    </template>
                    <el-row>
                        <el-col :span="12" class="center text">
                            <div class="grate fix-height" v-html="parking_count"></div>
                            <div class="small">产权数量（套）</div>
                        </el-col>
                        <el-col :span="12" class="center text">
                            <div class="grate fix-height" v-html="parking_area"></div>
                            <div class="small">产权面积（平米）</div>
                        </el-col>                        
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <!--第零行-->
        <!--第一行-->
        <el-row :gutter="20" class="pad">
            <el-col :span="8">
                <el-card>
                    <template #header>
                        <div class="card-header">
                            <span>居民</span>
                        </div>
                    </template>
                    <el-row>
                        <el-col :span="12" class="center text">
                            <div class="huge fix-height" v-html="list.build"></div>
                            <div class="small">楼宇</div>
                        </el-col>
                        <!-- <el-col :span="8" class="center text">
                            <div class="big fix-height" v-html="list.house"></div>
                            <div class="small">户数</div>
                        </el-col> -->
                        <el-col :span="12" class="center text">
                            <div class="big fix-height" v-html="list.people"></div>
                            <div class="small">业主</div>
                        </el-col>
                        
                    </el-row>
                </el-card>
            </el-col>
            <el-col :span="16">
                <el-card>
                    <template #header>
                        <div class="card-header">
                            <span>短信</span>
                        </div>
                    </template>
                    <el-row>
                        <el-col :span="7" class="center text">
                            <div class="big fix-height" v-html="list.sms_balance"></div>
                            <div class="small">短信余量</div>
                        </el-col>
                        <el-col :span="7" class="center text">
                            <div class="big fix-height" v-html="list.sms_used"></div>
                            <div class="small">累计用量</div>
                        </el-col>
                        <el-col :span="8" class="center text">
                            <div class="big date fix-height" v-html="list.expire_at"></div>
                            <div class="small">有效期</div>
                        </el-col>
                        
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <!--第一行-->
        <!--第二行-->
        <el-row :gutter="20" class="pad">
            <el-col :span="8">
                <el-card>
                    <template #header>
                        <div class="card-header">
                            <span>资讯</span>
                        </div>
                    </template>
                    <el-row>
                        <el-col :span="7" class="center text">
                            <div class="big fix-height" v-html="list.chn"></div>
                            <div class="small">栏目</div>
                        </el-col>
                        <el-col :span="7" class="center text">
                            <div class="big fix-height" v-html="list.art"></div>
                            <div class="small">资讯</div>
                        </el-col>
                        <el-col :span="10" class="center text">
                            <div class="big fix-height" v-html="list.read"></div>
                            <div class="small">阅读</div>
                        </el-col>
                    </el-row>
                    <div class="chart-box" ref="article"></div>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card>
                    <template #header>
                        <div class="card-header">
                            <span>调查问卷</span>
                        </div>
                    </template>
                    <el-row>
                        <el-col :span="6" class="center text">
                            <div class="big fix-height" v-html="list.ques_count"></div>
                            <div class="small">总数</div>
                        </el-col>
                        <el-col :span="10" class="center text">
                            <div class="big fix-height" v-html="list.ques_click"></div>
                            <div class="small">点击</div>
                        </el-col>
                        <el-col :span="8" class="center text">
                            <div class="big fix-height" v-html="list.ques_answer"></div>
                            <div class="small">参与</div>
                        </el-col>
                    </el-row>
                    <div class="chart-box" ref="question"></div>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card>
                    <template #header>
                        <div class="card-header">
                            <span>选举投票</span>
                        </div>
                    </template>
                    <el-row>
                        <el-col :span="6" class="center text">
                            <div class="big fix-height" v-html="list.vote_count"></div>
                            <div class="small">总数</div>
                        </el-col>
                        <el-col :span="10" class="center text">
                            <div class="big fix-height" v-html="list.vote_click"></div>
                            <div class="small">点击</div>
                        </el-col>
                        <el-col :span="8" class="center text">
                            <div class="big fix-height" v-html="list.vote_submit"></div>
                            <div class="small">参与</div>
                        </el-col>
                    </el-row>
                    <div class="chart-box" ref="vote"></div>
                </el-card>
            </el-col>
        </el-row>
        <!--第二行-->

        
    </div>
</template>
<script>
import {inject} from 'vue'
import * as echarts from 'echarts'
export default {
    setup:function(){
        const gd = inject('gd');
        return {gd};
    },//setup
    data:function(){
        return {
            areas:[],
            list:[],
        }
    },//data end
    mounted:function(){
        this.init();
    },//mounted end
    computed:{
        house_count:function(){
            return this.list.estate ? this.list.estate.house.count : 0;
        },//house_count
        house_area:function(){
            return this.list.estate ? this.list.estate.house.area : 0;
        },//house_area
        shop_count:function(){
            return this.list.estate ? this.list.estate.shop.count : 0;
        },//shop_count
        shop_area:function(){
            return this.list.estate ? this.list.estate.shop.area : 0;
        },//shop_area
        parking_count:function(){
            return this.list.estate ? this.list.estate.parking.count : 0;
        },//parking_count
        parking_area:function(){
            return this.list.estate ? this.list.estate.parking.area : 0;
        },//parking_area

    },//comouted
    watch:{
        'gd.me.eid':function(){
            this.init();
        },
    },//watch

  methods:{
    init: function(){
        this.load_data();
        
        
    },//init
    load_data:function(){
        this.axios.post("/api/estate/overview").then(ret=>{
            if(!ret.ok){ this.$message.error(ret.msg); return;}
            this.list = ret.data;
            this.render_article();
            this.render_question();
            this.render_vote();
        });
    },//load_data
    render_article:function(){
        let dates = [] , datas = [];
        //let d= new Date();
        // for(let i=0; i<30; i++){
        //     d.setDate(d.getDate()-1);
        //     dates.unshift((d.getMonth()+1)+"月"+d.getDate()+"日");
        //     datas.unshift(Math.round(Math.random()*1000+100));
        // }
        this.list.post_by_day.map(item=>{
            dates.push(item.day);
            datas.push(item.count);
        });

    	let chart = echarts.init(this.$refs.article);
        let option = {
        grid:{
          bottom:'10%',
          left:'10%',
          top:'15%',
          right:'5%'
        },
        tooltip: {
          backgroundColor:'rgba(0,70,135,0.7)',
          borderColor:'rgb(32, 50, 58)',
          textStyle:{
            color:'#fff'
          }
        },
        xAxis: {
          data: dates,
          axisTick:{show:false},
          axisLine:{
            lineStyle:{
              color:'rgb(116, 162, 211)'
            }
          }
        },
        yAxis: {
          show:true,
          axisLabel:{
            color:'rgb(115, 135, 160)'
          },
          splitNumber:2,
          axisLine:{
            show:true,
            lineStyle:{
              color:'rgb(116, 162, 211)'
            }
          },
          splitLine:{
            show:false,
          },
        },
        series: [{
              name: '点击量',
              type: 'bar',
              data: datas,
              itemStyle:{
                color:'rgb(32, 50, 58)'
              }
          }]
        };
        chart.setOption(option);
    },//rander_article
    render_question:function(){
        let data = [];
        data.push({name:'参与', value:this.list.ques_answer});
        data.push({name:'未参与', value:this.list.ques_click - this.list.ques_answer});
        let chart = echarts.init(this.$refs.question);
          let option = {
            grid:{
              bottom:0,
              left:0,
              top:0,
              right:0
            },
            avoidLabelOverlap: false,
            tooltip: {
                backgroundColor:'rgba(0,70,135,0.5)',
                borderColor:'rgb(32, 50, 58)',
                textStyle:{
                    color:'#fff'
                }
            },
            label:{
                color:'rgb(32, 50, 58)',
                backgroundColor:'transparent'
            },
            series: [
                {
                    type: 'pie',
                    radius: ['25%','70%'],
                    data: data,
                    itemStyle: {
                        borderRadius: 2,
                        borderColor: 'rgb(255,255,255)',
                        borderWidth: 1
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
          };
          chart.setOption(option);
    },//render_question
    render_vote:function(){
        let data = [];
        data.push({name:'参与', value:this.list.vote_submit});
        data.push({name:'未参与', value:this.list.vote_click - this.list.vote_submit});
        let chart = echarts.init(this.$refs.vote);
          let option = {
            grid:{
              bottom:0,
              left:0,
              top:0,
              right:0
            },
            avoidLabelOverlap: false,
            tooltip: {
                backgroundColor:'rgba(0,70,135,0.5)',
                borderColor:'rgb(32, 50, 58)',
                textStyle:{
                    color:'#fff'
                }
            },
            label:{
                color:'rgb(32, 50, 58)',
                backgroundColor:'transparent'
            },
            series: [
                {
                    type: 'pie',
                    radius: ['35%','70%'],
                    data: data,
                    itemStyle: {
                        borderRadius: 2,
                        borderColor: '#fff',
                        borderWidth: 1
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
          };
          chart.setOption(option);
    },//render_vote
  },//methods end
};
</script>